<template>
	<b-modal ref="ModalEditCentre">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('action.modifier_centre') }}</h2>
		</template>
		<template slot="default">
			<div class="form-group">
				<label>{{ $t('monte.reservation.centre_stockage') }}</label>
				<b-form-checkbox id="expediteur_modify" v-model="expediteur_modify">
					<label for="expediteur_modify">{{ $t("global.modifier") }}</label>
				</b-form-checkbox>
				<e-select
					:disabled="!expediteur_modify"
					v-model="expediteur"
					id="expediteur"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('monte.reservation.choisir_centre_stockage')"
					:selectedLabel="$t('global.selected_label')"
					:options="available_tiers"
					:show-labels="false"
				></e-select>
			</div>

			<label>{{ $t('monte.reservation.echeance') }}</label>
			<b-form-checkbox id="delivery_date_modify" v-model="delivery_date_modify">
				<label for="delivery_date_modify">{{ $t("global.modifier") }}</label>
			</b-form-checkbox>
			<e-datepicker v-model="delivery_date" :disabled="!delivery_date_modify" />
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="submitModal()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.sauvegarder') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
	import _cloneDeep from 'lodash/cloneDeep'
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"

	export default {
		name: 'ModalEditCentre',
		mixins: [MonteReservationOrder],
		props: {
			available_tiers: { type: Array, default: () => [] },
			reservation_order: { type: Array, default: () => [] }
		},
		data () {
			return {
				processing: false,
				expediteur: null,
				delivery_date: null,
				delivery_date_modify: false,
				expediteur_modify: false
			}
		},
		methods: {
			prepareModal() {
				if(!this.reservation_order) return
				this.resetModal()

				this.expediteur = this.reservation_order[0].expediteur
				if(this.reservation_order.length == 1 && this.reservation_order[0].delivery_date) {
					this.delivery_date = new Date(this.reservation_order[0].delivery_date)
				}
			},
			openModal() {
				this.$refs.ModalEditCentre.show()
			},
			closeModal() {
				this.$refs.ModalEditCentre.hide()
			},
			resetModal() {
				this.expediteur = null
				this.delivery_date = null
				this.delivery_date_modify = false
				this.expediteur_modify = false
			},
			async submitModal() {
				this.processing = true;
				if(this.delivery_date_modify || this.expediteur_modify) {
					const date = this.delivery_date ? this.delivery_date.toDateInputValue() : this.delivery_date
					const ids = this.reservation_order.map(r => r.id)
					await this.editExpediteurAndDate(ids, this.expediteur.tiers_id, date, this.delivery_date_modify, this.expediteur_modify)
				}
				this.processing = false
				this.$emit('ok')
				this.closeModal()
			}
		},
		watch: {
			reservation_order() {
				this.prepareModal()
			}
		}
	}

</script>